@import "../../assets/styles/utils";

footer{
  background: url(../../assets/images/footer_bg.jpg) no-repeat top center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  @include res(padding-top,.85rem,(xs:.6rem));
  .footer-logo{
    text-align: center;
    img{
      height: auto;
      @include res(width,2.78rem,(md:3.8rem,sm:4.5rem,xs:3.5rem));
    }
  }
  .footer-nav{
    @include res(margin-top, .95rem,(xs:.5rem));
    ul{
      align-items: stretch;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      li{
        @include res(border-bottom,null,(xs:1px solid rgba(255,255,255,.1)));
        .title{
          font-weight: bold;
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.32rem));
          @include res(margin-bottom, .15rem,(xs:0));
          a{
            flex-grow: 1;
            @include res(margin-left,0,(xs:.1rem));
            @include res(padding-top,0,(xs:.2rem));
            @include res(padding-bottom,0,(xs:.2rem));
          }
          .iconfont{
            transition: all .3s;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            @include res(display,none);
            @include res(width,1rem);
            @include res(font-size,.24rem);
          }
        }
        .sub-nav{
          zoom: 1;
          @include res(display,block,(xs:none));
          a{
            opacity: .8;
            display: block;
            transition: all .3s;
            @include res(padding-left,0,(xs:.3rem));
            @include res(padding-top,0,(xs:.1rem));
            @include res(padding-bottom,0,(xs:.1rem));
            @include res(margin-bottom, .1rem);
            @include res(width,null,(xs:33.33%));
            @include res(float,null,(xs:left));
            &:hover{
              opacity: 1;
            }
          }
          &::after{
            content: '';
            clear: both;
            display: block;
          }
        }
        &.active{
          .title{
            .iconfont{
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
  .footer-ewm{
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(margin-top, .6rem);
    @include res(margin-bottom, .35rem);
    .item{
      text-align: center;
      &:not(:last-child){
        @include res(margin-right,.5rem);
      }
      img{
        display: block;
        margin: 0 auto;
        height: auto;
        @include res(width,1.1rem,(md:1.6rem,sm:1.8rem));
      }
      p{
        @include res(margin-top,.15rem);
      }
    }
  }
  .copyright{
    text-align: center;
    border-top: solid rgba(255,255,255,.5);
    color: rgba(255,255,255,.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @include res(padding-top,.25rem);
    @include res(padding-bottom,.25rem);
    @include res(border-top-width,.01rem,(md:1px));
    @include res(font-size,.14rem,(md:.24rem,sm:.3rem,xs:.26rem));
    a{
      transition: all .3s;
      display: inline-block;
      @include res(margin-left,.05rem);
      @include res(margin-right,.05rem);
      &:hover{
        color: #fff;
      }
    }
    .site-map{
      @include res(display,null,(sm:none));
    }
    .police{
      display: inline-flex;
      align-items: center;
      img{
        display: block;
        width: 15px;
        margin-right: 3px;
      }
    }
  }
}