@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include res(margin-top,.6rem,(xs:.5rem));
  a,li{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s;
    @include res(margin-left,.02rem);
    @include res(margin-right,.02rem);
    @include res(padding-left,.1rem);
    @include res(padding-right,.1rem);
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.3rem));
    &:hover{
      color: $color-main;
    }
    &.active,&.xl-active{
      color: $color-main;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
    &.disabled{
      opacity: .5;
      pointer-events: none;
    }
    &.btn{
      color: $color-main;
      background-color: #fff;
      border-style: solid;
      border-color: $color-main;
      transition: all .3s;
      @include res(width,.4rem,(md:30px));
      @include res(height,.4rem,(md:30px));
      @include res(border-width, .01rem,(md:1px));
      @include res(margin-left,.2rem);
      @include res(margin-right,.2rem);
      &:hover{
        color: #fff;
        background-color: $color-main;
      }
    }
  }
  .page_jump_text{
    @include res(margin-left,.1rem);
    @include res(margin-right,.1rem);
  }
  .page_jump_input{
    @include res(width,.6rem,(md:.7rem,sm:.8rem,xs:.7rem));
    @include res(height,.3rem,(md:.4rem,sm:.5rem,xs:.4rem));
  }
  .page_jump_btn{
    background: none;
    border: none;
    cursor: pointer;
    transition: all;
    &:hover{
      color: $color-main;
    }
  }
}