@font-face {
  font-family: "iconfont"; /* Project id 4523281 */
  src: url('iconfont.woff2?t=1714382936220') format('woff2'),
       url('iconfont.woff?t=1714382936220') format('woff'),
       url('iconfont.ttf?t=1714382936220') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiazai:before {
  content: "\e626";
}

.icon-yulan:before {
  content: "\e62b";
}

.icon-guanbi:before {
  content: "\e614";
}

.icon-big-right:before {
  content: "\e603";
}

.icon-sousuo:before {
  content: "\e62d";
}

.icon-ln_xiangyoujiaohuan:before {
  content: "\e600";
}

.icon-bangongbao_o:before {
  content: "\e655";
}

.icon-arrow-right:before {
  content: "\e601";
}

.icon-big-left:before {
  content: "\e602";
}

.icon-a-dianhua2:before {
  content: "\eb40";
}

