@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'din';
  src: url('../fonts/DIN-Medium.woff') format('woff'),
    url('../fonts/DIN-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'song';
  src: url('https://ltd-client-video.oss-cn-beijing.aliyuncs.com/huanghaigongzhengchu/SourceHanSerifCN-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "Microsoft YaHei","PingFang SC";
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #f7f7f7;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.16rem,(md:.28rem,sm:.34rem,xs:.28rem));
}


//主体内容部分宽度
.container {
  @include res(padding-left,2rem,(md:1rem,xs:.3rem));
  @include res(padding-right,2rem,(md:1rem,xs:.3rem));
}

.common-title{
  align-items: flex-end;
  justify-content: space-between;
  @include res(display,flex);
  @include res(margin-bottom,.45rem);
  .t{
    display: flex;
    align-items: flex-end;
    h2{
      font-weight: bold;
      @include res(margin-right,.15rem);
      @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.36rem));
    }
    h3{
      color: $color-main;
      font-family: 'din';
    }
  }
}
.common-more{
  display: inline-block;
  border: solid $color-main;
  color: $color-main;
  transition: all .3s;
  white-space: nowrap;
  @include res(padding,.15rem .3rem);
  @include res(border-width,.01rem,(md:1px));
  @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.24rem));
  &:hover{
    color: #fff;
    background-color: $color-main;
  }
}
.pd{
  @include res(padding-top,1rem,(xs:.6rem));
  @include res(padding-bottom,1rem,(xs:.6rem));
}
.ny-menu{
  display: flex;
  justify-content: center;
  box-shadow: 0 0 .2rem rgba(0,0,0,.1);
  background-color: #fff;
  a{
    display: block;
    position: relative;
    transition: all .3s;
    @include res(padding,.3rem .45rem,(md:.4rem .45rem,sm:.4rem .5rem,xs:.3rem));
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.32rem));
    &::after{
      content: '';
      display: block;
      width: 100%;
      background-color: $color-main;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all .3s;
      opacity: 0;
      @include res(height,.02rem,(md:2px));
    }
    &:hover{
      color: $color-main;
    }
    &.active{
      color: $color-main;
      font-weight: bold;
      &::after{
        opacity: 1;
      }
    }
  }
}