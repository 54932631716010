@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  @include res(height,31.77vw,(md:36.77vw,sm:40vw,xs:60vw));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .cont{
    position: relative;
    z-index: 2;
    color: #fff;
    text-align: center;
    @include res(margin-top,$header-height-base,$header-height-ratio);
    h1{
      font-weight: bold;
      @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.36rem));
      @include res(margin-bottom,.2rem);
    }
    .crumbs{
      span{
        display: inline-block;
        @include res(margin-left,.05rem);
        @include res(margin-right,.05rem);
      }
    }
  }
}