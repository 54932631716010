@import "../../assets/styles/utils";

header{
  position: relative;
  &.scroll{
    .header{
      position: fixed;
      top: 0;
      background-color: #fff;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      @include res(height,1rem,$header-height-ratio);
      .logo{
        img{
          &.logo-white{opacity: 0;}
          &.logo-color{opacity: 1;}
        }
      }
      .right{
        .nav{
          color: #333;
          a{
            &::after{
              background-color: $color-main;
            }
          }
        }
        .search-icon{
          color: $color-main;
        }
        .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
          background-color: $color-main;
        }
      }
    }
    .header-search{
      @include res(height,1rem,$header-height-ratio);
    }
    .mob-header{
      @include res(padding-top, 3rem,(xs:1.3rem));
    }
  }
  &.show-menu{
    .header{
      background-color: #fff;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      .logo{
        img{
          &.logo-white{opacity: 0;}
          &.logo-color{opacity: 1;}
        }
      }
      .right{
        .nav{
          color: #333;
          a{
            &::after{
              background-color: $color-main;
            }
          }
        }
        .search-icon{
          color: $color-main;
        }
        .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
          background-color: $color-main;
        }
      }
    }
    .header-search{
      @include res(height,1rem,$header-height-ratio);
    }
  }
  &.trans{
    .header{
      transform: translateY(-100%);
    }
  }
  .header-top{
    background-color: #f1f9fb;
    color: #00538c;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    @include res(display,flex);
    @include res(height,.4rem,(md:.6rem,sm:.7rem,xs:.6rem));
    .left{
      @include res(display,null,(xs:none));
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.2rem));
    }
    .right{
      @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.22rem));
    }
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: background-color .4s,box-shadow .4s,transform .4s,height .4s;
    border-bottom: solid rgba(255,255,255,.8);
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(top,100%);
    @include res(height,$header-height-base,$header-height-ratio);
    .logo{
      position: relative;
      img{
        display: block;
        height: auto;
        transition: all .4s;
        @include res(width,3.87rem,(md:4.5rem,sm:5.5rem,xs:4.5rem));
        &.logo-white{
          position: absolute;
          top: 0;
          left: 0;
        }
        &.logo-color{
          opacity: 0;
        }
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex);
      .nav{
        color: #fff;
        height: 100%;
        @include res(display,block,(sm:none));
        @include res(font-size,.19rem,(md:.24rem));
        a{
          height: 100%;
          display: inline-flex;
          align-items: center;
          transition: color .3s;
          position: relative;
          @include res(margin-right,.6rem,(md:.5rem));
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            left: 50%;
            top: 70%;
            transition: all .3s;
            opacity: 0;
            transform: translateX(-50%);
            @include res(width,.05rem);
            @include res(height,.05rem);
          }
          &:hover,&.active{
            &::after{
              opacity: 1;
            }
          }
        }
      }
      .search-icon{
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color .4s;
        .iconfont{
          @include res(font-size,.26rem,(md:.38rem,sm:.54rem,xs:.44rem));
        }
      }
      .language{
        position: relative;
        text-transform: uppercase;
        @include res(font-size,.14rem,(md:.26rem,sm:.36rem,xs:.28rem));
        @include res(margin-left,.35rem,(sm:0));
        @include res(margin-right,0,(sm:.35rem));
        &:hover{
          .lan-title{
            border-bottom-color: transparent;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          .lan-cont{
            visibility: visible;
            >div{
              transform: translateY(0%);
            }
          }
        }
        .lan-title{
          border: solid #fff;
          color: #fff;
          border-radius: .06rem;
          transition: all .3s;
          cursor: pointer;
          @include res(padding-left,.2rem);
          @include res(padding-right,.12rem);
          @include res(border-width,.01rem,(md:1px));
        }
        .lan-cont{
          position: absolute;
          top: calc(100% - 1px);
          left: 0;
          width: 100%;
          overflow: hidden;
          visibility: hidden;
          >div{
            width: 100%;
            border: solid #fff;
            border-radius: .06rem;
            border-top: none;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            transition: all .3s;
            transform: translateY(-100%);
            @include res(border-width,.01rem,(md:1px));
          }
          a{
            display: block;
            color: #fff;
            transition: all .3s;
            @include res(padding-left,.2rem);
            &:hover{
              background-color: #fff;
              color: #000;
            }
          }
        }
      }
      .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
        background-color: #fff;
      }
      .hamburger{
        margin-left: .3rem;
        @include res(display,none,(sm:block));
      }
    }
  }
  .header-search{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $anime-bezier $anime-duration;
    transform: translateY(-100%);
    @include res(height,1.6rem,(md:2rem,sm:2.3rem,xs:1.7rem));
    &.active{
      transform: translateY(-.01rem);
      box-shadow: 0 0 .1rem rgba(#000,.1);
    }
    .header-search-logo{
      @include res(display,null,(xs:none));
      @include res(margin-right,.5rem);
      img{
        display: block;
        height: auto;
        transition: $anime-bezier $anime-duration;
        @include res(width,3.87rem,(md:4.5rem,sm:5.5rem,xs:4.5rem));
      }
    }
    form{
      flex-grow: 1;
      display: flex;
      align-items: center;
      input{
        flex-grow: 1;
        border: none;
        @include res(height,.5rem);
        @include res(font-size, .18rem,(md:16px));
      }
      button{
        background-color: #e4e4e4;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all .4s;
        @include res(margin-left,.1rem);
        @include res(width,.4rem,(md:40px));
        @include res(height,.4rem,(md:40px));
        .iconfont{
          @include res(font-size,.2rem,(md:20px));
        }
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .header-search-close{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-weight: bold;
      color: #000;
      cursor: pointer;
      margin-left: .1rem;
      @include res(width, .5rem,(md:50px));
      @include res(height,.5rem,(md:50px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        display: inline-block;
        transition: all .4s;
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 3rem,(xs:2rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.36rem,(sm:.48rem,xs:.32rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.24rem);
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          zoom: 1;
          @include res(padding-left, .6rem);
          @include res(padding-right, .6rem);
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            clear: both;
            display: block;
          }
          a{
            display: block;
            @include res(width,null,(md:33.33%));
            @include res(float,null,(md:left));
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}